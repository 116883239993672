import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl';
import AccordionSection from '../../Accordion/AccordionSection';
import { GreenhouseApi } from '../../../services/greenhouse';
import './styles.scss';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';

interface WwuList {
  jobsList: any
}

const WorkWithUsList: React.FC<WwuList> = ({jobsList}) => {
  
  const [departments, setDepartments] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [offices, setOffices] = useState([]);
  const [filterState, setFilterState] = useState('');

  const getJobs = async () => {
    const { data } = await axios.get(`https://boards-api.greenhouse.io/v1/boards/kushki/jobs?content=true`, {
      headers: {
      'Content-Type': 'application/json'
      }
    })
    const filterData = data.jobs.filter(item => item.content !== null)
    const jobsData = filterData.map((item: any) => {
      return {
        department: item.departments[0].name,
        title: item.title,
        location: item.location.name,
        job_id: item.internal_job_id,
        id: item.id
      }
    })
    setJobs(jobsData)
  }
  
  const dataApi = GreenhouseApi();
  
  const getFilterValue = async (ev: any, type: string) => {
    const { value }  = ev.currentTarget;
    if ( filterState === value ) {
      setFilterState('');
      getFilteredJobs('', type);
    } else {
      setFilterState(value);
      getFilteredJobs(value, type);
    }
  }

  const getFilteredJobs = (valueChecked: string, typeFilter: string) => {
    if (typeFilter === 'department') {
      const departmentJobs: any = departments.find(item => item.name === valueChecked);
      if (valueChecked === '') {
        getJobs();
      } else {
        setJobs(jobs.filter(item => item.department === valueChecked));
      }
    } else  {
      if (valueChecked === '') {
        getJobs();
      } else {
        setJobs(jobs.filter(item => item.location.split(' ').includes(valueChecked)));
      }
    }
  }
  
  const flatDepartments = (departments: any) => {
    let arrDepartments: any = [];
    departments.map((department: any) => {
      if(department.jobs.length > 0) {
        arrDepartments.push({
          id: department.id,
          name: department.name,
          jobs: department.jobs.map((job: any) => {
            return job.internal_job_id
          })
        })
      }
    })
    setDepartments(arrDepartments);
  }
  
  useEffect(() => {
    getJobs()
    dataApi.getDepartments()
    .then(res => {
      flatDepartments(res.departments);
    });
    dataApi.getOffices()
    .then(res => {
      setOffices(res.offices);
    });
  }, []);
  
  return (
    <section className="wwu-list py-layout-3">
      <div className="container">
        <div className="row">
          <div className="filters col-md-3">
            <div className="filter-aside">
              <div className="filter-aside__departments">
                <AccordionSection title={
                  <><FormattedMessage id="work_with_us.list_jobs.filters.departments"/></>
                }>
                  <ul className="filter-aside__content">
                    {
                      departments.map((item: any, index: number) => {
                        return (
                          <li className="filter-aside__item" key={index}>
                             <p>
                              <input type="checkbox" className="mr-2" 
                              onChange={ e => getFilterValue(e, 'department') } 
                              disabled={filterState !== '' && filterState !== item.name ? true : false}  
                              value={item.name} />
                               {item.name}
                              </p>
                          </li>
                        )
                      })
                    }
                  </ul>
                </AccordionSection>
              </div>
              <div className="filter-aside__departments">
                <AccordionSection title={
                  <><FormattedMessage id="work_with_us.list_jobs.filters.location"/></>
                }>
                  <ul className="filter-aside__content">
                    {
                      offices.map((item: any, index: number) => {
                        return (
                          <li className="filter-aside__item" key={index}>
                             <p>
                              <input type="checkbox" className="mr-2" 
                              onChange={ e => getFilterValue(e, 'office') }
                              disabled={filterState !== '' && filterState !== item.name ? true : false}
                              value={item.name} />
                               {item.name}
                              </p>
                          </li>
                        )
                      })
                    }
                  </ul>
                </AccordionSection>
              </div>
            </div>

          </div>
          <div className="col-md-9">
            <div>
              {
                jobs.map((item: any, index: number) => {
                  return (
                    <LocalizedLink to={`/work-with-us/${item.id}`} className="integration-card" key={index}>
                      <div className="card-body ml-4">
                        <div className="card-body__contain">
                          <div className="">
                            <h6 className="wwu-card_title text-primary">{item.title}</h6>
                            <p className="card-text text-dark-gray-1">{item.location}</p>
                          </div>
                          <div className="d-flex justify-content-end">
                            <p className="card-text__rigth text-primary">{item.department}</p>
                          </div>
                        </div>
                      </div>
                    </LocalizedLink>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkWithUsList