import React from 'react'
import { FormattedMessage } from 'react-intl';
import bgImg from './assets/images/hero.png'

import './styles.scss';

const WorkWithUsHero = () => {

  return (
    <div
      className="wwu-hero__background"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        height: '500px',
        marginTop: '4rem',
        width: '100%',
      }}
    >
      <section className="pt-layout-5 wwu-hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h1 className="display-4 text-white">
                <FormattedMessage id="work_with_us.hero.title" />
              </h1>
              <p className="text-white">
                <FormattedMessage id="work_with_us.hero.description" />
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default WorkWithUsHero