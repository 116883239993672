import { graphql } from 'gatsby';
import React from 'react'
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import WorkWithUsHero from '../components/WorkWithUs/WorkWithUsHero/WorkWithUsHero';
import WorkWithUsList from '../components/WorkWithUs/WorkWithUsList/WorkWithUsList';
import { LocaleTypes } from '../data/locales';
import IndexLayout from '../layouts';

interface WorkWithUsProps {
  data: {
    allGreenhouseJob: {
      nodes: Array<[
        {
          title: string;
          content: string;
          active: boolean;
          location: {
            name: string;
          }
          live: boolean;
          job_id: number;
          id: number;
        }
      ]>
    }
  }
  pathContext: {
    localeCode: LocaleTypes
  }
  location: Location
}

const WorkWithUs: React.FC<WorkWithUsProps> = ({data, pathContext, location}) => {

  const jobsList = data.allGreenhouseJob.nodes.filter(item => item.content !== null);

  const content = () => (
    <>
      <SEO titleId="work_with_us.title" />
       <WorkWithUsHero />
       <WorkWithUsList jobsList={jobsList} />
      {/*<WorkWithUsContent />
      <WorkWithUsForm /> */}
      <Footer theme="primary" />
      
    </>
  )

  return <IndexLayout render={content} locale={pathContext.localeCode} navbarTheme='light' location={location} />
}

export default WorkWithUs

export const query = graphql`
  query {
    allGreenhouseJob {
      nodes {
        active
        title
        location {
          name
        }
        live
        job_id
        id
        content
      }
    }
  }
`;